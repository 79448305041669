import * as _ from "lodash";
import * as log from "loglevel";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import axios from "axios";
import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import initMediaQuery from "./mediaQuery";
import "./registerServiceWorker";

import "./assets/css/global.scss";

if (window.location.href.indexOf("localhost") >= 0) {
  log.enableAll();
} else {
  log.setLevel("warn");
}

axios
  .get("/api/config/")
  .then((response) => {
    log.debug("try to config ApplicatgionInsights with", response.data);
    const appInsights = new ApplicationInsights({
      config: { connectionString: response.data.connection_string },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  })
  .catch((error) => {
    log.warn("Could not load config -> ApplicationInsights not configured", error);
  });

const tabletBreakpoint = 900;
const desktopBreakpoint = 1200;

/*
Sentry.init({
  dsn: "https://a59b70a61aac493ba2364c599b015003@sentry.io/1859997",
  integrations: [
    new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
  ],
});
 */

// Polyfills
if (!String.prototype.startsWith) {
  // CF-432: polyfill for IE11
  Object.defineProperty(String.prototype, "startsWith", {
    value: function (search, target, rawPos) {
      return _.startsWith(search, target, rawPos);
    },
  });
}

// https://vanillajstoolkit.com/polyfills/stringreplaceall/
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === "[object regexp]") {
      return this.replace(str, newStr);
    }

    // If a string
    return this.replace(new RegExp(str, "g"), newStr);
  };
}

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, "find", {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== "function") {
        throw TypeError("predicate must be a function");
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
    configurable: true,
    writable: true,
  });
}

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
function setCustomVh() {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

window.addEventListener("resize", () => {
  setCustomVh();
});

setCustomVh();

Vue.config.productionTip = false;

Vue.use(VueMeta);

initMediaQuery(store, tabletBreakpoint, desktopBreakpoint);

store.dispatch("loadCmsData");

router.afterEach((to) => {
  console.log(store.state.selectedLanguage);
  if (to.query.lang && _.includes(["de", "en", "fr"], to.query.lang)) {
    store.dispatch("setSelectedLanguage", to.query.lang);
  }

  // cleanup scrollPosition
  setTimeout(() => {
    store.commit("setScrollPosition", null);
  }, 100);
});

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

Vue.filter("dateFormat", function (value) {
  const d = new Date(value);
  if (!isValidDate(d)) {
    return value;
  }
  return `${_.padStart(d.getDate(), 2, "0")}.${_.padStart(
    d.getMonth() + 1,
    2,
    "0",
  )}.${d.getFullYear()}`;
});

Vue.filter("truncate", function (str, trunc, options = {}) {
  if (trunc && str) {
    return _.truncate(str, options);
  } else {
    return str;
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
