<template>
  <div class="key-figures-page" v-if="pageData.colorClass">
    <div class="modal" :class="{ 'is-active': showNoGraphicIE11Modal }">
      <div class="modal-background" />
      <div class="modal-content">
        <div style="color: white">
          Die Funktion "Grafik exportieren" wird im Internet Explorer nicht unterstützt
        </div>
        <div style="margin-top: 1em">
          <button class="modal-close-button" @click="closeIE11WarningModal()">
            Schliessen
          </button>
        </div>
      </div>
      <button class="modal-close is-large" @click="closeIE11WarningModal()" />
    </div>

    <core-figures-header
      :title="pageData.pageTitle"
      :text="pageData.pageHeaderText"
      :color-class="'cf-header--' + pageData.colorClass"
    />

    <section
      v-if="mainFilter"
      class="section snf-section snf-section__blue-light padding-top-1"
    >
      <div class="columns snf-section__columns">
        <div class="container column is-full" v-if="mainFilter">
          <div class="cf-main-filter">
            <div class="cf-main-filter__mobile-item">
              <SolrSelect
                :parsed-entry="mainFilter"
                @solrSelectValueChanged="solrSelectValueChanged"
              />
            </div>

            <div
              v-for="(value, index) in mainFilter.selectionValues"
              :key="index"
              class="cf-main-filter__item"
              :class="{
                'cf-main-filter__item--selected': index === mainFilter.selectedIndex,
              }"
              @click="mainFilterValueChanged(value)"
            >
              {{ value.text }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="section snf-section padding-top-1"
      :class="{ 'snf-section__blue-light': !mainFilter }"
    >
      <div class="columns snf-section__columns">
        <div class="container column is-full">
          <div
            v-if="cfParseSentenceResult"
            class="field is-grouped"
            style="flex-wrap: wrap; justify-content: flex-start"
          >
            <div
              class="control cf-control"
              v-for="(part, index) in cfParseSentenceResult.sentenceParts"
              v-show="part !== mainFilterIndex"
              :key="part + index"
              :style="{
                'margin-bottom': '0.5em',
                'margin-right': part[0] === '*' ? '0.75rem' : 0,
              }"
            >
              <SolrSelect
                v-if="part[0] === '*'"
                :parsed-entry="cfParseSentenceResult.parsedEntries[part]"
                @solrSelectValueChanged="solrSelectValueChanged"
              />

              <input
                v-if="part[0] !== '*'"
                class="input cf-input"
                :class="{ 'cf-input--blue-light': !mainFilterIndex }"
                type="text"
                tabindex="-1"
                :style="{
                  border: 'none',
                  width: getTextWidth(part) + 20 + 'px',
                  background: '#f0f0f0',
                  'margin-left': part === ')' ? '-0.75rem' : 0,
                  'margin-right': part === '(' ? '-0.75rem' : 0,
                }"
                :value="part"
                :aria-label="part"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <Loader v-show="showLoader || showLoaderHard"></Loader>
    <div v-show="!showLoader" v-if="!showLoaderHard">
      <section v-if="noEntityData" class="section snf-section margin-top-1">
        <div class="columns snf-section__columns">
          <div class="container column is-full">
            <div class="cf-result-sentence">
              {{ noEntityData }}
            </div>
          </div>
        </div>
      </section>

      <div v-else>
        <section class="section snf-section">
          <div class="columns snf-section__columns">
            <div class="container column is-full">
              <div class="cf-result-sentence" v-html="cfResultSentence" />
            </div>
          </div>
        </section>

        <section
          class="section snf-section graph-section margin-top-1 padding-bottom-2"
        >
          <div class="columns snf-section__columns">
            <div
              v-if="pageData.entityUrl === 'demographics' && $route.query.s1 === '3'"
              id="demographics-pyramid-id"
            ></div>

            <div
              v-else-if="pageData.entityUrl === 'demographics'"
              id="britecharts-chart"
              :class="{
                'britecharts-chart-horizontal': demographicsChartHorizontal,
                'britecharts-chart-vertical': !demographicsChartHorizontal,
              }"
              :style="{}"
            ></div>

            <div
              v-else-if="pageData.entityUrl === 'internationality'"
              class="internationality-section"
            >
              <div id="internationality-map-id">
                <img src="/img/internationality-empty.png" />
              </div>
              <div class="internationality-loader-wrapper">
                <div
                  class="internationality-loader"
                  v-if="showLoaderInternationality"
                />
              </div>
              <div id="internationality-legend-id"></div>
              <div id="internationality-legend-table-id">
                <table class="country-table">
                  <tbody>
                    <tr v-for="item in internationalityTableData" :key="item.label">
                      <td class="country-label">
                        <div
                          class="country-square"
                          :style="{
                            background: interpolateCountryColor(item.count),
                          }"
                        ></div>
                        {{ item.label }}
                      </td>
                      <td class="country-count">
                        {{ numberToStringFormat(item.count) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div v-else-if="cfSelectedParts['*1'].solrValue === '3'">
              <SuccessChart
                :data="cfPictureData"
                :property="
                  (pageData.entityUrl === 'supplementalFunding'
                    ? cfSelectedParts['*1'].solrValue === '1'
                      ? 'Grants'
                      : 'Funding'
                    : cfSelectedParts['*2'].solrValue === '1'
                    ? 'Grants'
                    : 'Funding') ?? 'Funding'
                "
              />
            </div>

            <div v-else-if="isMultiYears">
              <MultiYearChartWithOptions
                :data="cfPictureData"
                :y-label="
                  (pageData.entityUrl === 'supplementalFunding'
                    ? cfSelectedParts['*1']?.text
                    : cfSelectedParts['*2']?.text) ?? 'Projekte'
                "
                :filename="pageData.entityUrl"
              />
            </div>

            <WaffleChart
              v-else
              :graph-input="cfPictureData"
              :filename="pageData.entityUrl"
              :title="graphTitle"
              :show-legend="true"
            />
          </div>

          <div class="columns snf-section__columns column-download">
            <div class="container column is-full">
              <button class="download-button" @click="exportChart()">
                {{ exportGraphicText[selectedLanguage] }}
              </button>
              <a class="download-button margin-top-1" :href="pageData.csvUrl">
                {{ downloadButtonText[selectedLanguage] }}
              </a>
              <a class="download-button margin-top-1" :href="pageData.excelUrl">
                {{ excelDownloadButtonText[selectedLanguage] }}
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>

    <nav class="cf-nav snf-section snf-section__gray">
      <div class="cf-nav__section snf-section__columns columns is-mobile">
        <div class="cf-nav__left cf-nav__item column">
          <router-link class="text-decoration-none" to="/key-figures">
            <span>{{ cmsTranslationByKey("BackCoreFiguresHome") }}</span>
          </router-link>
        </div>
      </div>
    </nav>

    <section v-if="$route.query.debug" class="section snf-section column-download">
      <div v-if="cfSolrQuery" style="margin-top: 3em">
        <table>
          <tr v-for="entry in cfPictureData" :key="entry.label">
            <td style="width: 300px">
              {{ entry.label }}
            </td>
            <td>{{ entry.count }}</td>
          </tr>
        </table>

        <div style="margin-top: 2em">
          <a :href="cfSolrQuery" target="_blank">SOLR Query</a>
        </div>
        <pre style="margin-top: 2em">
        {{ cfPictureData }}
        </pre>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable no-inner-declarations */

import * as _ from "lodash";
import * as log from "loglevel";
import * as d3 from "d3";
import * as britecharts from "britecharts";

import SolrSelect from "../components/keyFigures/SolrSelect.vue";
import {
  CONTINENTS,
  createResultSentence,
  createSelectedSentence,
  fetchFullKeyFiguresData,
  fetchKeyFiguresData,
} from "../solrHelpers";
import WaffleChart from "../components/keyFigures/WaffleChart.vue";
import CoreFiguresHeader from "../components/keyFigures/CoreFiguresHeader.vue";
import { mapActions, mapGetters } from "vuex";
import { getTextWidth, numberToStringFormat, updateUrlQuery } from "../utils";
import { countryData } from "@/countryData";
import Loader from "@/components/Loader";
import { cmsTranslationMixin } from "@/mixins";
import MultiYearChartWithOptions from "@/components/keyFigures/MultiYearChartWithOptions.vue";
import SuccessChart from "@/components/keyFigures/SuccessChart.vue";

const internationalityCountryIndex = "*2";

export default {
  name: "KeyFiguresPage",
  components: {
    SuccessChart,
    MultiYearChartWithOptions,
    SolrSelect,
    WaffleChart,
    CoreFiguresHeader,
    Loader,
  },
  props: ["entity"],
  mixins: [cmsTranslationMixin],
  metaInfo: function () {
    if (this.pageData.colorClass) {
      return {
        title: `${this.cmsTranslationByKey("NavigationMainEntryCF")}: ${
          this.pageData.pageTitle
        }`,
        meta: [
          {
            vmid: "og:title",
            property: "og:title",
            content: `${this.cmsTranslationByKey("NavigationMainEntryCF")}: ${
              this.pageData.pageTitle
            }`,
          },
          {
            vmid: "og:description",
            property: "og:description",
            // remove html tags with content inside of tags
            content: this.pageData.pageHeaderText
              .replace(/<[^>]*>[^<]*<\/[^>]*>/g, "")
              .replace(/<[^>]*>/g, "")
              .replace(/\s+/g, " "),
          },
          {
            vmid: "og:image",
            property: "og:image",
            content: `${window.location.origin}/img/meta_image_key_figures.png`,
          },
        ],
      };
    }
    return {};
  },
  data: function () {
    return {
      pageData: {},
      showNoGraphicIE11Modal: false,
      pageIndex: null,
      noEntityData: null,
      showLoader: false,
      showLoaderInternationality: false,
      showLoaderHard: false,
      cfParseSentenceResult: null,
      cfSelectedParts: null,
      cfSolrQuery: "",
      cfPictureData: [],
      cfResultSentence: "",
      cfSolrSentences: null,
      demographicsChartHorizontal: false,
      demographicsChart: null,
      demographicsPyramidChart: null,
      geoChart: null,
      geoContinentSeries: {},
      geoEvents: [],
      downloadButtonText: {
        en: "Download (CSV)",
        fr: "Télécharger (CSV)",
        de: "Herunterladen (CSV)",
      },
      excelDownloadButtonText: {
        en: "Download (Excel)",
        fr: "Télécharger (Excel)",
        de: "Herunterladen (Excel)",
      },
      exportGraphicText: {
        en: "Export graphic (PNG)",
        de: "Grafik exportieren (PNG)",
        fr: "Exporter le graphique (PNG)",
      },
    };
  },
  mounted: function () {
    this.loadPageData();
  },
  beforeDestroy: function () {
    this.geoChart = null;
    this.demographicsPyramidChart = null;
    window.am4core.disposeAllCharts();
  },
  computed: {
    ...mapGetters(["selectedLanguage", "cmsData", "screenSize", "cmsPageDataArray"]),
    graphTitle() {
      if (!this.cfParseSentenceResult.parsedSentence) {
        return "";
      }

      return createSelectedSentence(
        this.cfParseSentenceResult.parsedSentence,
        this.cfSelectedParts,
      );
    },
    mainFilterIndex() {
      if (this.pageData.entityUrl === "demographics") {
        // CF-1049: Workaround for demographics page
        return "*1";
      } else {
        return this.cfParseSentenceResult?.levelInfo?.fieldIndex;
      }
    },
    mainFilter() {
      if (this.cfParseSentenceResult && this.mainFilterIndex) {
        return this.cfParseSentenceResult.parsedEntries[this.mainFilterIndex];
      }
      return null;
    },
    internationalityTableData() {
      if (this.pageData.entityUrl === "internationality") {
        const selectedContinentCode =
          this.cfSelectedParts[internationalityCountryIndex].solrValue;
        if (_.includes(CONTINENTS, selectedContinentCode)) {
          return _.orderBy(
            _.filter(this.cfPictureData, (c) => {
              return c.ContinentCode === selectedContinentCode;
            }),
            ["count", "label"],
            ["desc", "asc"],
          );
        } else {
          const continentData = _.groupBy(this.cfPictureData, "ContinentCode");
          return _.orderBy(
            _.map(continentData, (countries, continentCode, data) => {
              return {
                label: data[continentCode][0].labelContinent,
                count: _.sumBy(countries, "count"),
              };
            }),
            ["count", "label"],
            ["desc", "asc"],
          );
        }
      }
      return [];
    },
    callDecisionYears() {
      return _.filter(this.cfSelectedParts, (part) => {
        if (
          part.solrAttribute === "CallDecisionYear" ||
          part.solrAttribute === "DecisionYear"
        ) {
          return part;
        }
      });
    },
    isMultiYears() {
      const yearValues = _.map(this.callDecisionYears, "solrValue");
      return yearValues.length > 1 && yearValues[0] !== yearValues[1];
    },
  },
  watch: {
    $route(to, from) {
      log.debug("$route", to, from);
      if (from.path !== to.path) {
        this.loadPageData();
      }
    },
    selectedLanguage: function (val) {
      log.debug("watch selectedLanguage", val);
      this.loadPageData();
    },
    cmsData: function (val) {
      this.loadPageData();
    },
    screenSize: function (val) {
      log.debug("watch screenSize", val);
      if (this.pageData.entityUrl === "demographics") {
        this.loadPageData();
      }
    },
  },
  directives: { SolrSelect },
  methods: {
    ...mapActions({ exportGraphAsImg: "exportGraph" }),
    closeIE11WarningModal: function () {
      this.showNoGraphicIE11Modal = false;
    },
    getTextWidth: function (text) {
      return getTextWidth(text, "400 16px Source Sans Pro");
    },
    loadPageData: function () {
      this.cfSelectedParts = null;
      this.cfParseSentenceResult = null;

      this.showLoader = true;
      this.showLoaderHard = true;

      this.geoChart = null;
      this.demographicsPyramidChart = null;
      window.am4core.disposeAllCharts();

      if (this.selectedLanguage && this.cmsPageDataArray.length > 0) {
        this.pageData = _.find(this.cmsPageDataArray, {
          entityUrl: _.camelCase(this.entity),
        });
        this.pageIndex = _.findIndex(this.cmsPageDataArray, {
          entityUrl: _.camelCase(this.entity),
        });

        // TODO: add link to page header text for testing
        // this.pageData.pageHeaderText = this.pageData.pageHeaderText + "<br><a href='https://data.snf.ch/key-figures/documentation' target='_blank'>Link auf Dokumentation</a>"
        this.changeLanguage();
      }
    },
    changeLanguage: function () {
      this.noEntityData = null;

      fetchFullKeyFiguresData(
        this.selectedLanguage,
        this.pageData,
        this.$route.query,
      ).then((result) => {
        this.cfSelectedParts = result.cfSelectedParts;
        this.cfParseSentenceResult = result.cfParseSentenceResult;
        this.cfResultSentence = result.cfResultSentence;
        this.cfPictureData = result.cfPictureData;
        this.cfSolrSentences = result.cfSolrSentences;
        this.cfSolrQuery = result.cfSolrQuery;

        log.debug(JSON.parse(JSON.stringify(this.cfParseSentenceResult)));

        this.showLoaderHard = false;

        if (this.cfSolrSentences.length <= 0 || this.cfPictureData.length <= 0) {
          this.noEntityData = this.cmsTranslationByKey("NoDataForThisQuery");
          this.showLoader = false;
        } else {
          if (this.pageData.entityUrl === "demographics") {
            if (this.$route.query.s1 === "3") {
              this.createDemographicsPyramidChart(this.cfPictureData);
            } else {
              this.createDemographicsChart(this.cfPictureData);
            }
          } else if (this.pageData.entityUrl === "internationality") {
            this.createInternationalityMap();
          } else if (this.pageData.entityUrl === "fundingInstruments") {
            this.createFundingInstrumentsChart(this.cfPictureData);
          } else {
            this.showLoader = false;
          }
        }
      });
    },
    solrSelectValueChanged: function (newValue) {
      this.noEntityData = null;
      this.cfParseSentenceResult.parsedEntries[newValue.id].selectedIndex =
        newValue.index;
      this.cfSelectedParts[newValue.id] = newValue;
      updateUrlQuery(this.$router, this.$route.query, newValue, this.pageData);

      if (
        this.pageData.entityUrl === "internationality" &&
        newValue.id === internationalityCountryIndex
      ) {
        if (this.cfSolrSentences.length <= 0 || this.cfPictureData.length <= 0) {
          this.noEntityData = this.cmsTranslationByKey("NoDataForThisQuery");
          this.showLoader = false;
        } else {
          const continentCode = newValue.solrValue;

          this.zoomGeoChartContinent(continentCode);
          let totalNumber = _.sumBy(this.cfPictureData, "count");
          if (_.includes(CONTINENTS, continentCode)) {
            totalNumber = _.sumBy(
              _.filter(this.cfPictureData, { ContinentCode: continentCode }),
              "count",
            );
          }

          this.cfResultSentence = createResultSentence(
            this.cfSolrSentences,
            this.cfSelectedParts,
            totalNumber,
            0,
            this.selectedLanguage,
            this.pageData.solrEntity,
          );
        }
      } else {
        if (this.pageData.entityUrl === "demographics") {
          this.showLoader = true;
        }
        if (this.pageData.entityUrl === "internationality") {
          this.showLoaderInternationality = true;
        }

        fetchKeyFiguresData(
          this.selectedLanguage,
          this.pageData,
          this.cfParseSentenceResult,
          this.cfSelectedParts,
          this.cfSolrSentences,
        ).then((result) => {
          this.cfSelectedParts = result.cfSelectedParts;
          this.cfParseSentenceResult = result.cfParseSentenceResult;
          this.cfResultSentence = result.cfResultSentence;
          this.cfPictureData = result.cfPictureData;
          this.cfSolrSentences = result.cfSolrSentences;
          this.cfSolrQuery = result.cfSolrQuery;

          this.showLoaderHard = false;

          if (this.cfSolrSentences.length <= 0 || this.cfPictureData.length <= 0) {
            this.noEntityData = this.cmsTranslationByKey("NoDataForThisQuery");
            this.showLoader = false;
          } else {
            if (this.pageData.entityUrl === "demographics") {
              if (this.$route.query.s1 === "3") {
                this.createDemographicsPyramidChart(this.cfPictureData);
              } else {
                this.createDemographicsChart(this.cfPictureData);
              }
            } else if (this.pageData.entityUrl === "internationality") {
              this.updateInternationalityMap();
            }
          }
        });
      }
    },
    exportChart: function () {
      // check for Internet Explorer <= 11
      // https://stackoverflow.com/a/22242528/669561
      if (
        navigator.userAgent.indexOf("MSIE") !== -1 ||
        navigator.appVersion.indexOf("Trident/") > -1
      ) {
        this.showNoGraphicIE11Modal = true;
      } else {
        if (this.pageData.entityUrl === "demographics") {
          if (this.$route.query.s1 === "3") {
            this.demographicsPyramidChart.exporting.filePrefix = "demographics";
            this.demographicsPyramidChart.exporting.export("png");
          } else {
            this.demographicsChart.exportChart("demographics.png", this.graphTitle);
          }
        } else if (this.pageData.entityUrl === "internationality") {
          this.geoChart.exporting.filePrefix = "internationality";
          this.geoChart.exporting.export("png");
        } else {
          this.exportGraphAsImg();
        }
      }
    },

    createDemographicsChart: function (solrChartData) {
      _.delay(() => {
        window.am4core.disposeAllCharts();
      });

      _.delay(() => {
        this.demographicsChartHorizontal = this.screenSize === "mobile";
        const maxValue = _.maxBy(solrChartData, "value").value;
        let demographicsChartBlockNumber = 12;

        // normalize numbers for chart rendering
        if (this.cfSelectedParts["*2"].solrValue === "1") {
          if (this.cfSelectedParts["*1"].solrValue === "Total") {
            solrChartData[0].value = 380;
            demographicsChartBlockNumber = 14;
          }
          if (this.cfSelectedParts["*1"].solrValue === "m") {
            solrChartData[0].value = 280;
            demographicsChartBlockNumber = 10;
          }

          if (this.cfSelectedParts["*1"].solrValue === "f") {
            solrChartData[0].value = 160;
            demographicsChartBlockNumber = 6;
          }
        }

        if (this.cfSelectedParts["*2"].solrValue === "2") {
          if (this.cfSelectedParts["*1"].solrValue === "Total") {
            solrChartData[0].value = 1100;
            demographicsChartBlockNumber = 40;
          }
          if (this.cfSelectedParts["*1"].solrValue === "m") {
            solrChartData[0].value = 680;
            demographicsChartBlockNumber = 24;
          }

          if (this.cfSelectedParts["*1"].solrValue === "f") {
            solrChartData[0].value = 520;
            demographicsChartBlockNumber = 18;
          }
        }

        const chartData = _.orderBy(
          _.map(solrChartData, (d) => {
            return {
              name: d.name,
              value: Math.round((d.value / maxValue) * maxValue),
            };
          }),
          "name",
          this.demographicsChartHorizontal ? "desc" : "asc",
        );

        const data2 = chartData.map((e) => {
          let result = [];
          for (let i = 0; i < 100; i++) {
            if (e.value > i * demographicsChartBlockNumber) {
              result.push({
                name: e.name,
                value: demographicsChartBlockNumber,
                stack: "" + i,
              });
            } else {
              result.push({ name: e.name, value: 0, stack: "" + i });
            }
          }
          return result;
        });

        const data3 = [].concat.apply([], data2);

        const graphicMargin = 100;
        this.demographicsChart = null;

        const createHorizontalBarChart = () => {
          const barContainer = d3.select("#britecharts-chart");
          this.demographicsChart = new britecharts.stackedBar();

          d3.select("svg").remove();

          this.demographicsChart
            .isHorizontal(this.demographicsChartHorizontal)
            .betweenBarsPadding(0);
          if (this.demographicsChartHorizontal) {
            this.demographicsChart
              .aspectRatio(1.84)
              .width(Math.min(window.innerWidth, 1140))
              .grid("vertical")
              .xTicks(5);
          } else {
            this.demographicsChart
              .aspectRatio(0.54)
              .width(Math.min(window.innerWidth - 60, 1140) - graphicMargin * 2)
              .yTicks(10)
              .grid("horizontal");
          }

          barContainer.datum(data3).call(this.demographicsChart);
        };

        _.delay(() => {
          createHorizontalBarChart();
        }, 100);

        const redrawChart = () => {
          log.debug("redrawChart");
          const barContainer = d3.select("#britecharts-chart");

          // https://stackoverflow.com/q/8898412/669561
          // workaround for iOS Safari firing resizeEvents when width stays the same
          if (window.innerWidth !== this.windowWith) {
            this.windowWith = window.innerWidth;

            if (this.demographicsChartHorizontal) {
              this.demographicsChart.width(Math.min(window.innerWidth, 1140));
            } else {
              this.demographicsChart.width(
                Math.min(window.innerWidth - 60, 1140) - graphicMargin * 2,
              );
            }

            // Rendering the chart again
            barContainer.call(this.demographicsChart);
            log.debug(`width change with new widht ${this.windowWith}`);
          } else {
            log.debug("no width change");
          }
        };

        const throttledRedraw = _.throttle(redrawChart, 200);
        window.addEventListener("resize", throttledRedraw);

        this.showLoader = false;
      }, 100);
    },

    createDemographicsPyramidChart(solrChartData) {
      const that = this;
      _.delay(() => {
        window.am4core.disposeAllCharts();
      });

      _.delay(() => {
        window.am4core.addLicense("CH262563860");
        window.am4core.addLicense("MP262563860");

        const maxValue = Math.max(
          _.maxBy(solrChartData, "f").f,
          _.maxBy(solrChartData, "m").m,
        );
        if (solrChartData[0].name === "76") {
          solrChartData.shift();
        }

        const genderValues =
          that.cfParseSentenceResult.parsedEntries["*1"].selectionValues;
        const femaleText = _.find(genderValues, { solrValue: "f" }).text || "Female";
        const maleText = _.find(genderValues, { solrValue: "m" }).text || "Male";

        this.demographicsPyramidChart = window.am4core.create(
          "demographics-pyramid-id",
          window.am4core.Container,
        );
        this.demographicsPyramidChart.width = window.am4core.percent(100);
        this.demographicsPyramidChart.height = window.am4core.percent(100);
        this.demographicsPyramidChart.layout = "horizontal";

        const femaleChart = this.demographicsPyramidChart.createChild(
          window.am4charts.XYChart,
        );
        femaleChart.paddingRight = 0;
        femaleChart.data = solrChartData;

        femaleChart.legend = new window.am4charts.Legend();
        femaleChart.legend.position = "top";
        femaleChart.legend.labels.template.text = femaleText;

        const femaleCategoryAxis = femaleChart.yAxes.push(
          new window.am4charts.CategoryAxis(),
        );
        femaleCategoryAxis.dataFields.category = "name";
        femaleCategoryAxis.renderer.inversed = true;
        femaleCategoryAxis.renderer.grid.template.location = 0;
        femaleCategoryAxis.renderer.grid.template.disabled = true;
        femaleCategoryAxis.renderer.minGridDistance = 80;
        // femaleCategoryAxis.renderer.labels.template.rotation = -90;
        // femaleCategoryAxis.renderer.labels.template.verticalCenter = "bottom";
        // femaleCategoryAxis.title.text = "Age";
        femaleCategoryAxis.renderer.line.strokeOpacity = 1;
        femaleCategoryAxis.renderer.line.strokeWidth = 2;
        femaleCategoryAxis.renderer.line.stroke = window.am4core.color("#464646");

        const femaleValueAxis = femaleChart.xAxes.push(
          new window.am4charts.ValueAxis(),
        );
        femaleValueAxis.renderer.inversed = true;
        femaleValueAxis.min = 0;
        femaleValueAxis.max = maxValue * 1.1;
        femaleValueAxis.strictMinMax = true;
        femaleValueAxis.numberFormatter = new window.am4core.NumberFormatter();
        femaleValueAxis.numberFormatter.numberFormat = "#.#";

        const femaleSeries = femaleChart.series.push(
          new window.am4charts.ColumnSeries(),
        );
        femaleSeries.dataFields.valueX = "f";
        femaleSeries.fill = window.am4core.color("#fbbe5e");
        femaleSeries.stroke = window.am4core.color("white");
        femaleSeries.columns.template.tooltipText =
          femaleText + " {categoryY}: {valueX}";
        femaleSeries.dataFields.categoryY = "name";
        femaleSeries.interpolationDuration = 1000;

        const maleChart = this.demographicsPyramidChart.createChild(
          window.am4charts.XYChart,
        );
        maleChart.paddingLeft = 0;
        maleChart.data = solrChartData;

        maleChart.legend = new window.am4charts.Legend();
        maleChart.legend.position = "top";
        maleChart.legend.labels.template.text = maleText;

        const maleCategoryAxis = maleChart.yAxes.push(
          new window.am4charts.CategoryAxis(),
        );
        maleCategoryAxis.dataFields.category = "name";
        maleCategoryAxis.renderer.opposite = true;
        maleCategoryAxis.renderer.inversed = true;
        maleCategoryAxis.renderer.grid.template.location = 0;
        maleCategoryAxis.renderer.grid.template.disabled = true;
        maleCategoryAxis.renderer.minGridDistance = 80;
        // femaleCategoryAxis.renderer.labels.template.rotation = -90;
        // femaleCategoryAxis.renderer.labels.template.verticalCenter = "bottom";
        // maleCategoryAxis.title.text = "Age";
        maleCategoryAxis.renderer.line.strokeOpacity = 1;
        maleCategoryAxis.renderer.line.strokeWidth = 2;
        maleCategoryAxis.renderer.line.stroke = window.am4core.color("#464646");

        const maleValueAxis = maleChart.xAxes.push(new window.am4charts.ValueAxis());
        // maleValueAxis.renderer.inversed = true;
        maleValueAxis.min = 0;
        maleValueAxis.max = maxValue * 1.1;
        maleValueAxis.strictMinMax = true;
        maleValueAxis.numberFormatter = new window.am4core.NumberFormatter();
        maleValueAxis.numberFormatter.numberFormat = "#.#";

        const maleSeries = maleChart.series.push(new window.am4charts.ColumnSeries());
        maleSeries.dataFields.valueX = "m";
        maleSeries.fill = window.am4core.color("#5298bd");
        maleSeries.stroke = window.am4core.color("white");
        maleSeries.dataFields.categoryY = "name";
        maleSeries.interpolationDuration = 1000;
        maleSeries.columns.template.tooltipText = maleText + " {categoryY}: {valueX}";

        this.showLoader = false;
      }, 100);
    },

    createFundingInstrumentsChart(solrChartData) {
      const that = this;
      _.delay(() => {
        window.am4core.disposeAllCharts();
      });

      _.delay(() => {
        window.am4core.addLicense("CH262563860");
        window.am4core.addLicense("MP262563860");

        this.showLoader = false;
      }, 100);
    },

    zoomGeoChartContinent(continentCode) {
      const zoomContinentsGeoPoints = {
        AS: {
          north: 50,
          east: 45,
          south: 0,
          west: 145,
        },
        AF: {
          north: 31,
          east: 45,
          south: -29,
          west: -11,
        },
        EU: {
          north: 70,
          east: 40,
          south: 35,
          west: -10,
        },
        NA: {
          north: 80,
          east: -50,
          south: 20,
          west: -150,
        },
        OC: {
          north: 0,
          east: 175,
          south: -47,
          west: 110,
        },
        SA: {
          north: 15,
          east: -30,
          south: -50,
          west: -100,
        },
      };

      _.forEach(_.keys(this.geoContinentSeries), (code) => {
        this.geoContinentSeries[code].show();
      });

      const geo = zoomContinentsGeoPoints[continentCode];
      if (geo) {
        this.geoContinentSeries[continentCode].hide();
        this.geoChart.zoomToRectangle(
          geo.north,
          geo.east,
          geo.south,
          geo.west,
          1,
          true,
        );
      } else {
        this.geoChart.goHome();
      }
    },

    updateInternationalityMap() {
      this.geoHeatLegend.dispose();
      this.geoEvents.forEach((e) => {
        e.dispose();
      });
      this.geoEvents = [];
      this.geoWorldSeries.heatRules.clear();
      this.geoWorldSeries.data = _.map(this.cfPictureData, (p) => {
        return {
          id: p.CountryIsoCode,
          value: p.count,
          valueString: numberToStringFormat(p.count, this.selectedLanguage),
          name: p.label,
        };
      });

      const maxValue = _.orderBy(this.cfPictureData, ["count"], ["desc"])[0].count;
      this.geoWorldSeries.heatRules.push({
        property: "fill",
        target: this.geoWorldSeries.mapPolygons.template,
        min: window.am4core.color("#fae164"),
        max: window.am4core.color("#ab0000"),
        minValue: 1,
        maxValue: maxValue,
        logarithmic: false,
      });

      this.createGeoHeatLegend(maxValue, this.geoLegendContainer);

      this.showLoader = false;
      this.showLoaderInternationality = false;
    },

    createInternationalityMap() {
      _.delay(() => {
        window.am4core.disposeAllCharts();
      });
      this.showLoader = false;
      this.showLoaderInternationality = true;

      _.delay(() => {
        //const relevantData = _.clone(this.cfPictureData);

        // Create map instance
        window.am4core.addLicense("CH262563860");
        window.am4core.addLicense("MP262563860");
        this.geoChart = window.am4core.create(
          "internationality-map-id",
          window.am4maps.MapChart,
        );
        this.geoChart.geodata = window.am4geodata_worldHigh;
        this.geoChart.projection = new window.am4maps.projections.Miller();
        this.geoChart.events.on("ready", () => {
          this.showLoader = false;
          _.delay(() => {
            this.zoomGeoChartContinent(
              this.cfSelectedParts[internationalityCountryIndex].solrValue,
            );
          }, 200);
        });

        if (this.selectedLanguage === "de") {
          this.geoChart.geodataNames = window.am4geodata_lang_DE;
        } else if (this.selectedLanguage === "fr") {
          this.geoChart.geodataNames = window.am4geodata_lang_FR;
        }

        // whole world
        this.geoChart.homeZoomLevel = 0;
        this.geoChart.homeGeoPoint = {
          latitude: 30,
          longitude: 20,
        };

        // Create map polygon series
        this.geoWorldSeries = this.geoChart.series.push(
          new window.am4maps.MapPolygonSeries(),
        );
        window.geoWorldSeries = this.geoWorldSeries;

        // Make map load polygon (like country names) data from GeoJSON
        this.geoWorldSeries.useGeodata = true;
        this.geoWorldSeries.exclude = ["AQ"];

        // single country
        this.geoWorldSeries.data = _.map(this.cfPictureData, (p) => {
          return {
            id: p.CountryIsoCode,
            value: p.count,
            valueString: numberToStringFormat(p.count, this.selectedLanguage),
            name: p.label,
          };
        });
        const polygonTemplate = this.geoWorldSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name} {valueString}";
        polygonTemplate.fill = window.am4core.color("#E8E8E8");

        this.geoWorldSeries.tooltip.getFillFromObject = false;
        this.geoWorldSeries.tooltip.background.fill = window.am4core.color("white");
        this.geoWorldSeries.tooltip.background.fillOpacity = 1;
        this.geoWorldSeries.tooltip.label.fill = window.am4core.color("black");

        // Create hover state and set alternative fill color
        this.geoWorldSeries.mapPolygons.template.fillOpacity = 1;
        const hs = polygonTemplate.states.create("hover");
        //hs.properties.fill = window.am4core.color("#4159AC");
        hs.properties.fillOpacity = 0.8;
        //hs.properties.fillOpacity = 0.2;

        // hover state for whole continents https://www.amcharts.com/docs/v4/tutorials/highlight-all-polygons-in-map-series-on-hover/
        const createSeries = (continentCode, countryList, hoverColor) => {
          const series = this.geoChart.series.push(
            new window.am4maps.MapPolygonSeries(),
          );
          series.name = _.find(this.cfPictureData, {
            ContinentCode: continentCode,
          }).labelContinent;
          series.useGeodata = true;
          series.include = countryList;
          series.value = _.sumBy(
            _.filter(this.cfPictureData, { ContinentCode: continentCode }),
            "count",
          );
          series.valueString = numberToStringFormat(
            series.value,
            this.selectedLanguage,
          );
          //series.fill = window.am4core.color("green");
          series.events.on("over", over);
          series.events.on("out", out);

          series.mapPolygons.template.adapter.add("tooltipText", (text, target) => {
            const countryId = target.tooltipDataItem.dataContext.id;
            const countryData = _.find(this.cfPictureData, {
              CountryIsoCode: countryId,
            });

            const continentValue = _.sumBy(
              _.filter(this.cfPictureData, { ContinentCode: continentCode }),
              "count",
            );
            const continentValueString = numberToStringFormat(
              continentValue,
              this.selectedLanguage,
            );
            if (countryData) {
              const countryValueString = numberToStringFormat(countryData.count);
              return `${countryData.label} ${countryValueString}\n{series.name} ${continentValueString}`;
            } else {
              return "{name} 0\n{series.name} {series.value}";
            }
          });
          series.mapPolygons.template.fill = window.am4core.color("rgba(0, 0, 0, 0)");
          series.tooltip.getFillFromObject = false;
          series.tooltip.background.fill = window.am4core.color("white");
          series.tooltip.background.fillOpacity = 1;
          series.tooltip.label.fill = window.am4core.color("black");

          const hover = series.mapPolygons.template.states.create("highlight");
          hover.properties.fill = window.am4core.color(hoverColor);

          series.events.on("hit", (ev) => {
            const continentValue = _.find(
              this.cfParseSentenceResult.parsedEntries[internationalityCountryIndex]
                .selectionValues,
              { solrValue: continentCode },
            );
            this.cfParseSentenceResult.parsedEntries[
              internationalityCountryIndex
            ].selectedIndex = continentValue.index;
            this.solrSelectValueChanged(continentValue);
          });

          return series;
        };

        this.geoContinentSeries = {};
        _.forEach(_.groupBy(countryData, "continent"), (c) => {
          const continentCode = c[0].continent;
          this.geoContinentSeries[continentCode] = createSeries(
            continentCode,
            _.map(c, "id"),
            "rgba(255, 255, 255, 0.5)",
          );
        });

        function over(ev) {
          ev.target.mapPolygons.each(function (polygon) {
            polygon.setState("highlight");
          });
        }

        function out(ev) {
          ev.target.mapPolygons.each(function (polygon) {
            polygon.setState("default");
          });
        }

        // Add heat rule
        const maxValue = _.orderBy(this.cfPictureData, ["count"], ["desc"])[0].count;
        this.geoWorldSeries.heatRules.push({
          property: "fill",
          target: this.geoWorldSeries.mapPolygons.template,
          min: window.am4core.color("#fae164"),
          max: window.am4core.color("#ab0000"),
          minValue: 1,
          maxValue: maxValue,
          logarithmic: false,
        });

        // Add heat legend
        this.geoLegendContainer = window.am4core.create(
          "internationality-legend-id",
          window.am4core.Container,
        );
        this.geoLegendContainer.width = window.am4core.percent(100);
        this.geoLegendContainer.height = window.am4core.percent(100);

        const rect = this.geoLegendContainer.createChild(window.am4core.Rectangle);
        rect.width = window.am4core.percent(9);
        rect.height = 20;
        rect.fill = window.am4core.color("#dddddd");
        rect.fillOpacity = 1;
        const label = this.geoLegendContainer.createChild(window.am4core.Label);
        label.text = "0";
        label.align = "left";
        label.y = 21;

        this.createGeoHeatLegend(maxValue, this.geoLegendContainer);

        // Add zoom control
        this.geoChart.zoomControl = new window.am4maps.ZoomControl();

        // Add button to zoom out
        const homeButtom = this.geoChart.chartContainer.createChild(
          window.am4core.Button,
        );
        homeButtom.padding(7, 7, 7, 7);
        homeButtom.align = "right";
        homeButtom.valign = "bottom";
        homeButtom.marginBottom = 90;
        homeButtom.marginRight = 5;
        homeButtom.events.on("hit", () => {
          const continentValue =
            this.cfParseSentenceResult.parsedEntries[internationalityCountryIndex]
              .selectionValues[0];
          this.cfParseSentenceResult.parsedEntries[
            internationalityCountryIndex
          ].selectedIndex = continentValue.index;
          this.solrSelectValueChanged(continentValue);
        });
        homeButtom.icon = new window.am4core.Sprite();
        homeButtom.icon.path =
          "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";

        this.showLoaderInternationality = false;
      }, 100);
    },

    interpolateCountryColor(count) {
      const color1 = [250, 225, 100];
      const color2 = [171, 0, 0];

      const factor = _.clamp(count / _.maxBy(this.cfPictureData, "count").count, 0, 1);

      let result = color1.slice();
      for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
      }

      return `rgb(${result[0]}, ${result[1]}, ${result[2]})`;
    },

    createGeoHeatLegend(maxValue, legendContainer) {
      this.geoHeatLegend = this.geoLegendContainer.createChild(
        window.am4maps.HeatLegend,
      );
      // window.geoHeatLegend = this.geoHeatLegend;
      this.geoHeatLegend.series = this.geoWorldSeries;
      this.geoHeatLegend.maxValue = maxValue;
      this.geoHeatLegend.width = window.am4core.percent(90.5);
      this.geoHeatLegend.height = 60;
      const markerCount = 4;
      // this.geoHeatLegend.markerCount = markerCount;
      this.geoHeatLegend.valueAxis.logarithmic = false;
      this.geoHeatLegend.valign = "top";
      this.geoHeatLegend.align = "right";
      this.geoHeatLegend.valueAxis.tooltip.getFillFromObject = false;
      this.geoHeatLegend.valueAxis.tooltip.background.fill =
        window.am4core.color("white");
      this.geoHeatLegend.valueAxis.tooltip.background.fillOpacity = 1;
      this.geoHeatLegend.valueAxis.tooltip.label.fill = window.am4core.color("black");

      const minRange = this.geoHeatLegend.valueAxis.axisRanges.create();
      minRange.label.horizontalCenter = "left";
      const maxRange = this.geoHeatLegend.valueAxis.axisRanges.create();
      maxRange.label.horizontalCenter = "right";
      this.geoHeatLegend.valueAxis.renderer.labels.template.adapter.add(
        "text",
        function (labelText) {
          return "";
        },
      );

      // Update heat legend value labels
      this.geoEvents.push(
        this.geoWorldSeries.events.on("datavalidated", (ev) => {
          const minValue = 1;
          //const maxValue = this.geoHeatLegend.series.dataItem.values.value.high;

          minRange.value = minValue;
          minRange.label.text = numberToStringFormat(minValue, this.selectedLanguage);

          maxRange.value = maxValue;
          maxRange.label.text = numberToStringFormat(maxValue, this.selectedLanguage);

          for (let i = 1; i < markerCount; i++) {
            const range = this.geoHeatLegend.valueAxis.axisRanges.create();
            const value = Math.round(((maxValue + minValue) / markerCount) * i);
            range.value = value;
            range.label.text = numberToStringFormat(value, this.selectedLanguage);
          }
        }),
      );

      this.geoEvents.push(
        this.geoWorldSeries.mapPolygons.template.events.on("over", (ev) => {
          if (!isNaN(ev.target.dataItem.value)) {
            this.geoHeatLegend.valueAxis.showTooltipAt(ev.target.dataItem.value);
          } else {
            this.geoHeatLegend.valueAxis.hideTooltip();
          }
        }),
      );

      this.geoEvents.push(
        this.geoWorldSeries.mapPolygons.template.events.on("out", (ev) => {
          this.geoHeatLegend.valueAxis.hideTooltip();
        }),
      );
    },

    mainFilterValueChanged(item) {
      log.debug("mainFilterValueChanged", item);
      this.solrSelectValueChanged(Object.assign({}, item));
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

.key-figures-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.graph-section {
  flex: 1;
}

.modal-close-button {
  text-transform: none;
  padding: 0.75rem;
  border: 1px solid gray;
  color: white;
  background-color: transparent;
  cursor: pointer;
  margin-right: 1em;
}

.column-download {
  @include mobile() {
    display: none !important;
  }
}

.cf-input {
  padding-left: 3px !important;
  padding-right: 3px !important;
  background: white !important;

  &--blue-light {
    background: $snf-blue-highlight !important;
  }
}

.cf-result-sentence {
  font-size: 1.2rem;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.cf-nav {
  height: 80px;

  &__section {
    height: 80px;
    align-items: center;
  }

  &__left {
    & a span:before {
      content: "\2190";
      margin-right: 0.75rem;
    }

    flex-basis: auto !important;
  }

  &__right {
    text-align: right;

    & a span:after {
      content: "\2192";
      margin-left: 0.75rem;
    }

    flex-basis: auto !important;
  }
}

.cf-main-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &__mobile-item {
    @include desktop() {
      display: none;
    }
  }

  &__item {
    @include touch() {
      display: none;
    }

    margin-right: 2em;
    flex: 0 0 auto;
    color: $snf-blue-dark;
    cursor: pointer;

    &--selected {
      font-weight: 700;
    }
  }
}

#demographics-pyramid-id {
  width: 100%;
  max-width: 840px;
  height: 600px;
}

#britecharts-chart {
  .bar {
    fill: #83d0f5;
    stroke: white;
    stroke-width: 1px;
  }

  .horizontal-grid-line {
    stroke-width: 2;
  }
}

.britecharts-chart-horizontal {
  .bar:nth-child(1) {
    fill: transparent !important;
  }

  .y-axis-group .tick:nth-child(5n) {
    display: none;
  }

  .y-axis-group .tick:nth-child(5n + 1) {
    display: none;
  }

  .y-axis-group .tick:nth-child(5n + 2) {
    display: none;
  }

  .y-axis-group .tick:nth-child(5n + 4) {
    display: none;
  }

  .y-axis-group .tick:nth-child(5n + 5) {
    display: none;
  }
}

.britecharts-chart-vertical {
  .bar:nth-child(57) {
    fill: transparent !important;
  }

  .x-axis-group .tick:nth-child(5n + 1) {
    display: none;
  }

  .x-axis-group .tick:nth-child(5n + 3) {
    display: none;
  }

  .x-axis-group .tick:nth-child(5n + 4) {
    display: none;
  }

  .x-axis-group .tick:nth-child(5n + 5) {
    display: none;
  }
}

.internationality-section {
  margin-left: 25px;
  margin-right: 25px;
  width: calc(100% - 50px);
  position: relative;
}

#internationality-legend-id {
  margin-top: 10px;
  height: 60px;
}

#internationality-map-id {
  width: 100%;
  height: calc(100vh - 500px);
  min-height: 200px;
  max-height: 800px;
}

@media (min-aspect-ratio: 1/1) {
  #internationality-map-id {
    height: 70vh;
    min-height: 250px;
  }
}

#internationality-legend-table-id {
  .country-table {
    margin: 2em auto;
    max-width: 800px;

    width: 100%;
    table-layout: fixed;

    th,
    td {
      padding: 10px;
    }

    tr {
      border-top: 1px solid $snf-gray-medium;
    }

    tr:first-child {
      border-top: none;
    }

    .country-label {
      width: 80%;
    }
  }

  .country-square {
    width: 1em;
    height: 1em;
    float: left;
    margin-top: 4px;
    margin-right: 20px;
    background: rgb(147, 0, 0);
  }
}

.internationality-loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 200px;
}

.internationality-loader {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: $snf-black;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
