<template>
  <div class="data-story" :lang="selectedLanguage">
    <Loader v-if="showLoader"></Loader>

    <div v-show="!showLoader">
      <iframe
        scrolling="no"
        src="about:blank"
        ref="storyHolder"
        :style="storyFrameStyle"
      />

      <div class="data-story-meta" v-show="story.id">
        <div class="symbols">
          <a class="symbol-email" :href="shareEmailUrl" target="_blank"></a>
          <a
            v-show="story.GitHub_URL"
            class="symbol-github"
            target="_blank"
            :href="story.GitHub_URL"
          ></a>
          <a
            v-show="story.DOI"
            class="symbol-doi"
            target="_blank"
            :href="story.DOI"
          ></a>
        </div>
        <div class="tags">
          <a
            class="tag"
            v-for="tagId in story.tagList"
            :key="tagId"
            :href="`/stories?tag=${tagId}`"
          >
            {{ tags.length ? tags.find((t) => t.TagId === tagId).label : tagId }}
          </a>
        </div>

        <div class="authors" v-html="authorsHtml"></div>

        <div class="authors">
          <h3>{{ cmsTranslationByKey("ContactPerson") }}</h3>
          <p>
            <a style="text-decoration: underline" href="mailto:datastories@snf.ch">
              datastories@snf.ch
            </a>
          </p>
        </div>

        <div class="similar-stories" v-show="similarStories.length">
          <h2 class="similar-stories--title">
            {{ cmsTranslationByKey("SubheaderSimilarStories") }}
          </h2>

          <div
            v-for="story in similarStories"
            class="similar-story"
            :key="story.id"
            @click="openSimilarStory(story)"
          >
            <hr />
            <h3 class="story-title">
              <router-link
                :to="{
                  name: 'DataStory',
                  params: {
                    htmlFilename: story.Name,
                  },
                }"
              >
                {{ story.title }}
              </router-link>
            </h3>

            <p class="story-date">
              {{ story.PublicationDate | dateFormat }}
            </p>

            <div class="story-link">
              <router-link
                :to="{
                  name: 'DataStory',
                  params: {
                    htmlFilename: story.Name,
                  },
                }"
              >
                {{ cmsTranslationByKey("ButtonToStory") }}
                {{ readOnLanguagesText(story.Languages) }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as log from "loglevel";
import * as _ from "lodash";

import { mapActions, mapGetters } from "vuex";
import { buildEntityQuery, loadDataStories } from "@/solrHelpers";
import { decompressDataStoryData } from "@/utils";
import { cmsTranslationMixin } from "@/mixins";
import Loader from "@/components/Loader";

// show image in story iframe full screen -> function outside of vue
window.showImgFullscreen = function (src) {
  let modal;

  function removeModal() {
    document.body.removeChild(modal);
  }

  modal = document.createElement("div");
  modal.style.background = "RGBA(0,0,0,.5) url(" + src + ") no-repeat center";
  modal.style.backgroundSize = "contain";
  modal.style.width = "100%";
  modal.style.height = "100%";
  modal.style.position = "fixed";
  modal.style.zIndex = "10000";
  modal.style.top = "0";
  modal.style.left = "0";
  modal.style.cursor = "zoom-out";
  modal.addEventListener("click", () => {
    removeModal();
  });
  document.body.appendChild(modal);
};

// all keys
/*
const solrFields = [
  "Author",
  "AuthorURL",
  "Category",
  "Created",
  "DOI",
  "DataStoryCategory",
  "DataStoryName",
  "Entity",
  "GitHub_URL",
  "Language",
  "Languages",
  "Modified",
  "Name",
  "PublicationDate",
  "ShortDescription_De",
  "ShortDescription_En",
  "ShortDescription_Fr",
  "Tags",
  "ThumbnailExtension",
  "Title_De",
  "Title_En",
  "Title_Fr",
  "TopStory",
  "id"
]
 */

const solrFields = [
  "Author",
  "Author_De",
  "Author_Fr",
  "Author_En",
  "AuthorURL",
  "Category",
  "Created",
  "DOI",
  "DataStoryCategory",
  "DataStoryName",
  "Entity",
  "GitHub_URL",
  "Language",
  "Languages",
  "Modified",
  "Name",
  "PublicationDate",
  "Title_De",
  "Title_En",
  "Title_Fr",
  "ShortDescription_De",
  "ShortDescription_En",
  "ShortDescription_Fr",
  "Tags",
  "TopStory",
  "id",

  "FileLink",
  "ThumbnailLink",
];

export default {
  name: "DataStory",
  metaInfo: function () {
    if (this.story.id) {
      let imageEntry = {
        vmid: "og:image",
        property: "og:image",
        content: `${window.location.origin}/img/meta_image_data_stories.png`,
      };

      if (this.story.ThumbnailLink) {
        imageEntry.content = `${window.location.origin}${this.story.ThumbnailLink}`;
      }

      return {
        title: this.objectAttributeInLanguage(this.story, "Title"),
        meta: [
          {
            vmid: "og:title",
            property: "og:title",
            content: this.objectAttributeInLanguage(this.story, "Title"),
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: this.objectAttributeInLanguage(this.story, "ShortDescription"),
          },
          imageEntry,
        ],
      };
    }
    return {};
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["htmlFilename"],
  mixins: [cmsTranslationMixin],
  components: { Loader },
  data: function () {
    return {
      htmlFileContent: "",
      storyFrameStyle: {
        width: "100%",
        height: "100vh",
      },
      story: {},
      similarStories: [],
      showLoader: false,
    };
  },
  mounted: function () {
    log.debug("DataStory mounted", this.htmlFilename, this.$route.query);

    if (!window.localStorage.getItem("selectedLanguage")) {
      // CF-743 if the user has no stored language then she is here for the first time
      // set language accordingly
      const storyLang = _.split(this.htmlFilename, ".")[0].slice(-2);
      if (["en", "fr"].includes(storyLang)) {
        this.setSelectedLanguage(storyLang);
      }
    }

    this.loadPageData();
    if (!this.dataStoriesTags.length) {
      this.loadDataStoriesTags();
    }
  },
  computed: {
    ...mapGetters(["selectedLanguage", "cmsData", "screenSize", "dataStoriesTags"]),

    tags: function () {
      return _.map(this.dataStoriesTags, (t) => {
        if (this.selectedLanguage === "en") {
          t.label = t.English;
        } else if (this.selectedLanguage === "fr") {
          t.label = t.French;
        } else {
          t.label = t.German;
        }
        return t;
      });
    },
    encodedUrl: function () {
      return window.encodeURIComponent(window.location.href);
    },
    shareEmailUrl: function () {
      return `mailto:?subject=${window.encodeURIComponent(
        "SNF Data Story: ",
      )}${window.encodeURIComponent(
        this.objectAttributeInLanguage(this.story, "Title"),
      )}&body=${window.location.href}`;
    },
    authorsHtml: function () {
      // const names = _.split(this.story.Author, ";");
      const names = _.split(this.objectAttributeInLanguage(this.story, "Author"), ";");
      // const emails = _.split(this.story.AuthorURL, ";");

      let result;
      result = `<h3>${this.cmsTranslationByKey(
        names.length > 1 ? "Authors" : "Author",
      )}</h3>`;
      result += '<ul style="list-style-type: disc;">';

      result += _.map(names, (n) => {
        return `<li style="margin-left: 24px;">${n}</li>`;
      }).join("");

      result += "</ul>";

      return result;
    },
  },
  watch: {
    $route(to, from) {
      log.debug("$route", to, from);
      this.loadPageData();
    },
    selectedLanguage: function (val) {
      log.debug("watch selectedLanguage", val);
      const storyLang = _.split(this.story.Name, ".")[0].slice(-2);
      if (_.includes(this.story.Languages, val) && storyLang !== val) {
        this.loadDataStory(this.story.DataStoryName, val).then(() => {
          this.$router.push({
            name: this.$route.name,
            params: { htmlFilename: this.story.Name },
          });
        });
      }

      this.loadSimilarStories();
    },
  },
  methods: {
    ...mapActions(["loadDataStoriesTags", "setSelectedLanguage"]),
    loadPageData() {
      this.showLoader = true;
      axios
        .get(
          buildEntityQuery(`Name:${this.htmlFilename}`, 1) +
            `&sort=Modified%20desc&fl=${_.join(solrFields, ",")}`,
        )
        .then((response) => {
          this.handleSolrPageData(response);
        });
    },

    loadSimilarStories() {
      loadDataStories(
        this.selectedLanguage,
        this.story.tagList,
        3,
        this.story.DataStoryName,
      ).then((stories) => {
        this.similarStories = stories;
      });
    },

    loadDataStory(dataStoryName, language) {
      this.showLoader = true;
      return axios
        .get(
          buildEntityQuery(
            `DataStoryName:${dataStoryName}+AND+Language:${_.upperCase(language)}`,
            1,
          ) + `&sort=Modified%20desc&fl=${_.join(solrFields, ",")}`,
        )
        .then((response) => {
          this.handleSolrPageData(response);
        });
    },

    openSimilarStory(story) {
      this.$router.push({
        name: "DataStory",
        params: {
          htmlFilename: story.Name,
        },
      });
    },

    handleSolrPageData(response) {
      const storyData = response.data.response.docs[0];

      this.story = storyData;
      this.story.tagList = _.split(storyData.Tags, ",");

      this.loadSimilarStories();

      // FIXME: remove testing for DSS-1193
      // if (storyData.FileLink.startsWith("/")) {
      //   storyData.FileLink = "https://data.snf.ch" + storyData.FileLink;
      // }

      axios.get(storyData.FileLink).then((response) => {
        let htmlFileContent = response.data;

        // FIXME: remove testing for DSS-1193
        // htmlFileContent = htmlFileContent.replace(
        //   "https://data.snf.ch",
        //   "http://localhost:8080",
        // );

        htmlFileContent = htmlFileContent.replace(
          "</head>",
          `<style type="text/css">
          :root {
            font-size: 16px;
          }

          body {
            font-family: 'Theinhardt', sans-serif;
            margin: 0 auto;
            padding-bottom: 20px; /* Prevent overlay on the bottom of the iframe */
            max-width: 660px;
            color: #1a1a1a;
          }

          h1 {
            font-size: 48px;
            line-height: 50px;
            font-weight: 700;
            margin-top: 1.5em !important
          }

          h2 {
            font-size: 36px;
            line-height: 40px;
            font-weight: 700;
            margin-top: 1em;
          }

          h3 {
            font-size: 28px;
            line-height: 32px;
            font-weight: 700;
            margin-top: 1em;
          }

          a {
            color: #1a1a1a;
            text-decoration: underline;
          }

          a:hover {
            color: #c95b40;
            transition:
              color .25s ease-out;
              transition-duration: 0.25s;
              transition-timing-function: ease-out;
              transition-delay: 0s;
              transition-property: color;
          }

          p {
            line-height: 1.75;
            font-size: 1.1rem;
            margin: 1em 0 3em 0;
          }

          @media screen and (max-width: 768px) {
            body {
              margin: 0 20px;
            }

            h1 {
              font-size: 36px;
              line-height: 40px;
            }

            h2 {
              font-size: 32px;
              line-height: 36px;
              font-weight: 700;
              margin-top: 1em;
            }

            h3 {
              font-size: 28px;
              line-height: 32px;
              font-weight: 700;
              margin-top: 1em;
            }
          }
        </style></head>`,
        );

        /* eslint-disable no-useless-escape */
        htmlFileContent = htmlFileContent.replace(
          "</body>",
          `<script>
          Array.prototype.slice.call(document.getElementsByTagName('img')).forEach((elem) => {
            elem.addEventListener("click", function() {
              window.parent.showImgFullscreen(elem.getAttribute("src"));
            });
          });
          <\/script>
          </body>`,
        );

        // DSS-1193 open internal links in the same window
        /* eslint-disable no-useless-escape */
        htmlFileContent = htmlFileContent.replace(
          "</body>",
          `<script>
          Array.prototype.slice.call(document.getElementsByTagName('a')).forEach((elem) => {
            elem.addEventListener("click", function(e) {
              const target = e.target;
              const url = target.href;
              if (
                url.startsWith("https://data.snf.ch") ||
                url.startsWith("https://snfcorefigures.iterativ.ch") ||
                url.startsWith("http://localhost:8080")
              ) {
                e.preventDefault(); // Prevent the default link behavior
                parent.location.assign(url); // Go to the URL
              } else {
                // For other URLs, open in a new tab
                target.target = "_blank";
              }
            });
          });
          <\/script>
          </body>`,
        );

        //console.log(htmlFileContent);
        //this.$refs.storyHolder.src = "data:text/html;charset=utf-8," + htmlFileContent;
        const iframeDoc = this.$refs.storyHolder.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(htmlFileContent);
        iframeDoc.close();

        // Timeouts are needed to get correct height of the contentDom
        _.delay(() => {
          this.showLoader = false;
        }, 100);
        _.delay(() => {
          const contentDom = iframeDoc.body;
          const newHeight =
            Math.max(
              contentDom.clientHeight,
              contentDom.scrollHeight,
              contentDom.offsetHeight,
            ) +
            60 +
            "px";
          this.storyFrameStyle.height = newHeight;
        }, 200);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.data-story {
  hyphens: auto;
  border-top: $snf-gray-light 1px solid;
  margin-bottom: 2em;
}

.data-story-meta {
  margin: 0 16px;
  max-width: 668px;

  @media (min-width: 768px) {
    margin: auto;
  }

  .symbols {
    display: flex;
    margin: 1.5em 0;

    a[class^="symbol-"] {
      width: 26px;
      height: 26px;
      background-repeat: no-repeat;
      background-position: center;
      margin: 4px 8px;

      &:first-child {
        margin-left: 0;
      }
    }

    .symbol-twitter {
      background-image: url("../../public/img/icon-twitter.svg");
    }

    .symbol-facebook {
      background-image: url("../../public/img/icon-facebook.svg");
    }

    .symbol-email {
      background-image: url("../../public/img/icon-email.svg");
    }

    .symbol-github {
      background-image: url("../../public/img/icon-github.svg");
    }

    .symbol-doi {
      background-image: url("../../public/img/icon-doi.svg");
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
  }

  .tag {
    border: $snf-gray-dark 1px solid;
    padding: 6px 12px;
    margin: 10px 10px 10px 0;

    &:hover {
      background-color: $snf-blue-dark;
      color: $snf-white;
    }
  }

  .authors {
    margin: 1.5em 0;
  }

  .similar-stories {
    margin-bottom: 4em;

    h2 {
      font-size: 36px;
      line-height: 40px;
      font-weight: 700;
      margin-top: 1em;
    }

    h3 {
      font-size: 28px;
      line-height: 32px;
      font-weight: 700;
      margin-top: 1em;
    }

    .similar-story {
      margin: 16px 0;

      h3 {
        margin-top: 0;
      }

      hr {
        background-color: $snf-gray-medium;
        border: none;
        display: block;
        height: 1px;
        margin: 32px -16px;
      }
    }

    .story-date {
      margin-top: 16px;
    }

    .story-link {
      margin-top: 2em;
      margin-bottom: 1em;

      a {
        border: $snf-black 1px solid;
        padding: 12px;
      }
    }
  }

  @media (min-width: 768px) {
    .similar-stories {
      &--title {
        margin-bottom: 24px;
      }

      .similar-story {
        padding: 24px;
        margin: 0;
        border-top: $snf-gray-medium 1px solid;
        border-left: $snf-gray-medium 1px solid;
        border-right: $snf-gray-medium 1px solid;

        &:last-child {
          border-bottom: $snf-gray-medium 1px solid;
        }

        transition: transform 0.3s ease;
        &:hover {
          background-color: $snf-gray-light;
          cursor: pointer;
        }

        hr {
          display: none;
        }
      }
    }
  }
}
</style>
