var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.isEmptyData)?_c('div',{staticClass:"no-data-message"},[_c(_setup.NoDataMessage)],1):_c('div',[(_vm.$route.query.debug)?_c('div',[_c('div',[_c('button',{staticClass:"button toggle-button",class:{
            'is-primary': _setup.chartType == 'line',
          },on:{"click":function($event){_setup.chartType = 'line'}}},[_vm._v(" Line ")]),_c('button',{staticClass:"button toggle-button",class:{
            'is-primary': _setup.chartType == 'column',
          },on:{"click":function($event){_setup.chartType = 'column'}}},[_vm._v(" Bar ")])]),_c('div',[_c('button',{staticClass:"button toggle-button",class:{
            'is-primary': _setup.stacked,
          },on:{"click":function($event){_setup.stacked = true}}},[_vm._v(" Stacked ")]),_c('button',{staticClass:"button toggle-button",class:{
            'is-primary': !_setup.stacked,
          },on:{"click":function($event){_setup.stacked = false}}},[_vm._v(" Unstacked ")])])]):_vm._e(),_c('div',[_c(_setup.MultiYearChart,{attrs:{"data":_vm.data,"type":_setup.chartType,"stacked":_setup.stacked,"colors":_setup.colors,"y-label":_vm.yLabel,"filename":_vm.filename}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }