import * as _ from "lodash";
import * as log from "loglevel";
import axios from "axios";
import pako from "pako";

export const PRISMIC_ID_404 = "XqL-wxEAACMAY-Y7";

export function parseBasicStatsTopic(responseJson) {
  return {
    topics: responseJson.response.docs.map((d) => {
      const de = JSON.parse(d.de);
      const fr = JSON.parse(d.fr);
      const en = JSON.parse(d.en);
      return {
        de: {
          title: de.data.title[0].text,
          statement: de.data.statement[0].text,
        },
        fr: {
          title: fr.data.title[0].text,
          statement: fr.data.statement[0].text,
        },
        en: {
          title: en.data.title[0].text,
          statement: en.data.statement[0].text,
        },
      };
    }),
  };
}

export function parseSolrCmsResponse(responseJson) {
  const docs = responseJson.response.docs;
  return _.map(docs, (d) => {
    const id = d.id.substring(0, 16);
    return _.assign({}, d, {
      de: JSON.parse(d.de || "{}"),
      fr: JSON.parse(d.fr || "{}"),
      en: JSON.parse(d.en || "{}"),
      id: id,
    });
  });
}

export function updateUrlQuery($router, queryParams, solrSelectValue, pageData) {
  queryParams = _.cloneDeep(queryParams);
  const queryParamKey = solrSelectValue.id.replace("*", "s");
  if (parseInt(solrSelectValue.index, 10) === 0) {
    console.log(solrSelectValue);
    // TODO: hack to make single years work...
    if (
      pageData &&
      ((pageData.entityUrl === "internationality" && solrSelectValue.id === "*3") ||
        (pageData.entityUrl === "fundingInstruments" && solrSelectValue.id === "*5") ||
        (pageData.entityUrl === "researchInstitutions" &&
          solrSelectValue.id === "*5") ||
        (pageData.entityUrl === "disciplines" && solrSelectValue.id === "*5") ||
        (pageData.entityUrl === "supplementalFunding" && solrSelectValue.id === "*2"))
    ) {
      queryParams[queryParamKey] = solrSelectValue.index;
    } else {
      delete queryParams[queryParamKey];
    }
  } else {
    queryParams[queryParamKey] = solrSelectValue.index;
  }
  $router.replace({ query: queryParams }).catch(() => {});
}

export const pandemicApplicationEntities = [
  "Application",
  "Pandemic_ApplicationEU",
  "Pandemic_ApplicationInnosuisse",
];

export const pandemicApplicationEntitySubquery = _.join(
  _.map(pandemicApplicationEntities, (application) => {
    return `Entity:${application}`;
  }),
  "+OR+",
);

export const PANDEMIC_APPLICANT_ROLE_ID = "C4E66661-CF57-4D74-BD7D-247E63423B02";
export const PANDEMIC_CO_APPLICANT_ROLE_ID = "5325881C-66F3-4AD1-80F8-F9D970EFD3DB";
export const PANDEMIC_PROJECT_PARTNER_ROLE_ID = "280A54B5-0B05-449D-9228-05B887BF8FF8";

export const GRANT_FILTER_MAPPING = [
  {
    id: "filterApplicant",
    titleKey: "Applicants",
    grantSolrFields: ["AllApplicant_PersonId_mvf"],
    filterSolrFields: ["AllApplicant_PersonId_mvf"],
    urlQueryParams: ["applicant"],
    numLevels: 1,
    facetActive: true,
    facetLimit: 20,
    type: "person",
    nameField: "AllApplicantName_mvf",
    showLimit: 5,
  },
  {
    id: "filterEmployee",
    titleKey: "Employees",
    grantSolrFields: ["Employee_PersonId_mvf"],
    filterSolrFields: ["Employee_PersonId_mvf"],
    urlQueryParams: ["employee"],
    numLevels: 1,
    facetActive: true,
    facetLimit: 20,
    type: "person",
    nameField: "EmployeeName_mvf",
    showLimit: 5,
  },
  {
    id: "filterProjectPartner",
    titleKey: "ProjectPartners",
    grantSolrFields: ["ProjectPartner_PersonId_mvf"],
    filterSolrFields: ["ProjectPartner_PersonId_mvf"],
    urlQueryParams: ["partner"],
    numLevels: 1,
    facetActive: true,
    facetLimit: 20,
    type: "person",
    nameField: "ProjectPartnerName_mvf",
    showLimit: 5,
  },
  {
    id: "filterContactPerson",
    titleKey: "ContactPersons",
    grantSolrFields: ["ContactPerson_PersonId_mvf"],
    filterSolrFields: ["ContactPerson_PersonId_mvf"],
    urlQueryParams: ["contact"],
    numLevels: 1,
    facetActive: true,
    facetLimit: 20,
    type: "person",
    nameField: "ContactPersonName_mvf",
    showLimit: 5,
  },
  {
    id: "filterStartDate",
    titleKey: "StartDate",
    grantSolrFields: ["EffectiveGrantStartDate"],
    filterSolrFields: ["EffectiveGrantStartDate"],
    urlQueryParams: ["start"],
    numLevels: 1,
    facetActive: false,
    type: "date",
  },
  {
    id: "filterEndDate",
    titleKey: "EndDate",
    grantSolrFields: ["EffectiveGrantEndDate"],
    filterSolrFields: ["EffectiveGrantEndDate"],
    urlQueryParams: ["end"],
    numLevels: 1,
    facetActive: false,
    type: "date",
  },
  {
    id: "filterStatus",
    titleKey: "State",
    entity: "Filter_Status",
    grantSolrFields: ["StateId"],
    filterSolrFields: ["StateId"],
    urlQueryParams: ["state"],
    titleFields: ["State"],
    numLevels: 1,
    facetActive: true,
    type: "filter",
    showLimit: 8,
  },
  {
    id: "filterCall",
    titleKey: "Call",
    entity: "Filter_Call",
    grantSolrFields: ["CallDecisionYear", "CallId"],
    filterSolrFields: ["CallDecisionYear", "CallId"],
    urlQueryParams: ["call-decision-year", "call-id"],
    titleFields: ["CallDecisionYear", "CallFullTitle"],
    numLevels: 2,
    facetActive: true,
    type: "filter",
    showLimit: 5,
  },
  {
    // CF-1049: special country type
    // IMPORTANT: must be ordered before 'filterCountry'
    id: "filterCountryType",
    titleKey: "Country",
    entity: "Filter_Country_Type",
    grantSolrFields: ["CountryIsoCodeType_mvf"],
    filterSolrFields: ["CountryIsoCodeType"],
    urlQueryParams: ["country-type"],
    titleFields: ["Type"],
    numLevels: 1,
    facetActive: false,
    type: "filter",
  },
  {
    id: "filterCountry",
    titleKey: "Country",
    entity: "Filter_Country",
    grantSolrFields: ["CountryIsoCode_mvf", "CountryIsoCodeType_mvf"],
    filterSolrFields: ["CountryIsoCode", "CountryIsoCodeType"],
    urlQueryParams: ["country", "country-type"],
    titleFields: ["Country", "Type"],
    numLevels: 2,
    facetActive: true,
    type: "filter",
    showLimit: 5,
  },
  {
    id: "filterDiscipline",
    titleKey: "Discipline",
    entity: "Filter_Discipline",
    grantSolrFields: [
      "DisciplineLevel1Id_mvf",
      "DisciplineLevel2Id_mvf",
      "DisciplineLevel3Id_mvf",
    ],
    filterSolrFields: [
      "DisciplineLevel1Id",
      "DisciplineLevel2Id",
      "DisciplineLevel3Id",
    ],
    urlQueryParams: ["discipline-l1", "discipline-l2", "discipline-l3"],
    titleFields: ["DisciplineLevel1", "DisciplineLevel2", "DisciplineLevel3"],
    numLevels: 3,
    facetActive: true,
    type: "filter",
    showLimit: 5,
  },
  {
    id: "filterFieldsOfResearch",
    titleKey: "FieldOfResearch",
    entity: "Filter_FieldsOfResearch",
    grantSolrFields: ["FieldOfResearchLevelAId_mvf", "FieldOfResearchLevelBId_mvf"],
    filterSolrFields: ["LevelA_FieldOfResearchId", "LevelB_FieldOfResearchId"],
    urlQueryParams: ["field-l1", "field-l2"],
    titleFields: ["LevelA_FieldOfResearch", "LevelB_FieldOfResearch"],
    numLevels: 2,
    facetActive: true,
    type: "filter",
    // FIXME: how many should we show?
    showLimit: 30,
  },
  {
    id: "filterInstitution",
    titleKey: "ResearchInstitution",
    entity: "Filter_ResearchInstitution",
    grantSolrFields: ["ResearchInstitutionTypeId", "ResearchInstitutionId"],
    filterSolrFields: ["ResearchInstitutionTypeId", "ResearchInstitutionId"],
    urlQueryParams: ["institution-l1", "institution-l2"],
    titleFields: ["ResearchInstitutionType", "ResearchInstitution"],
    numLevels: 2,
    facetActive: true,
    type: "filter",
    showLimit: 8,
  },
  {
    id: "filterFundingInstrument",
    titleKey: "FundingInstrument",
    entity: "Filter_FundingInstrument",
    grantSolrFields: [
      "FundingInstrumentLevel1Id",
      "FundingInstrumentReportingId",
      "FundingInstrumentPublishedId_Frontend",
    ],
    filterSolrFields: [
      "FundingInstrumentLevel1Id",
      "FundingInstrumentReportingId",
      "FundingInstrumentPublishedId_Frontend",
    ],
    urlQueryParams: ["funding-l1", "funding-l2", "funding-l3"],
    titleFields: [
      "FundingInstrumentLevel1",
      "FundingInstrumentReporting",
      "FundingInstrumentPublished_Frontend",
    ],
    numLevels: 3,
    facetActive: true,
    type: "filter",
    showLimit: 5,
  },
  {
    id: "filterOutput",
    titleKey: "OutputData",
    entity: "Filter_OutputdataTypes",
    grantSolrFields: ["OutputdataTypeId_mvf", "OutputSubtypeId_mvf"],
    filterSolrFields: ["OutputdataTypeId", "OutputSubtypeId"],
    urlQueryParams: ["output-l1", "output-l2"],
    titleFields: ["OutputdataType", "OutputSubtype"],
    numLevels: 2,
    facetActive: true,
    type: "filter",
    showLimit: 10,
  },
];

export const FILTER_ENTRIES_LIST = [
  "filterFundingInstrument",
  "filterCountry",
  "filterApplicant",
  "filterProjectPartner",
  "filterEmployee",
  // 'filterContactPerson',
  "filterInstitution",
  "filterStatus",
  "filterStartDate",
  "filterEndDate",
  "filterCall",
  "filterDiscipline",
  "filterFieldsOfResearch",
  "filterOutput",
];

export const FILTER_ENTRIES_LIST_SORT_VALUE = _.map(FILTER_ENTRIES_LIST, (entry) => {
  return {
    id: entry,
    sortValue: FILTER_ENTRIES_LIST.indexOf(entry),
  };
}).reduce((acc, curr) => {
  acc[curr.id] = curr.sortValue;
  return acc;
}, {});

export const OUTPUT_DATA_TYPES = [
  {
    title: "ScientificPublications",
    id: "6055997F-BD82-4EAF-9694-399393DF7024",
    grantSolrField: "OutputScientificPublicationId_mvf",
    solrField: "OutputScientificPublicationId",
    entity: "OutputScientificPublication",
    key: "scientificPublications",
    sortField: "Year",
  },
  {
    title: "Datasets",
    id: "BD3800B0-CCAC-49EB-8733-4E511967B24D",
    grantSolrField: "OutputDataSetId_mvf",
    solrField: "OutputDataSetId",
    entity: "OutputDataSet",
    key: "dataSets",
    sortField: "PublicationDate",
  },
  {
    title: "Collaborations",
    id: "099C4A7F-FE8D-4B5C-B5CE-CBBCB3203AAF",
    grantSolrField: "OutputCollaborationId_mvf",
    solrField: "OutputCollaborationId",
    entity: "OutputCollaboration",
    key: "collaborations",
    sortField: "",
  },
  {
    title: "AcademicEvents",
    id: "3315AF68-DE9B-472D-91DB-E1632114C204",
    grantSolrField: "OutputAcademicEventId_mvf",
    solrField: "OutputAcademicEventId",
    entity: "OutputAcademicEvent",
    key: "academicEvents",
    sortField: "Date",
  },
  {
    title: "KnowledgeTransferEvents",
    id: "69F6890F-08D2-483D-966B-1ABDBB9C2C5C",
    grantSolrField: "OutputKnowledgeTransferEventId_mvf",
    solrField: "OutputKnowledgeTransferEventId",
    entity: "OutputKnowledgeTransferEvent",
    key: "knowledgeTransferEvents",
    sortField: "Date",
  },
  {
    title: "Awards",
    id: "812B245D-CCC1-4B58-9701-D41221F4350F",
    grantSolrField: "OutputAwardId_mvf",
    solrField: "OutputAwardId",
    entity: "OutputAward",
    key: "awards",
    sortField: "Year",
  },
  {
    title: "PublicCommunications",
    id: "C7D79C93-4625-4257-847F-2675AC8B9D6B",
    grantSolrField: "OutputPublicCommunicationId_mvf",
    solrField: "OutputPublicCommunicationId",
    entity: "OutputPublicCommunication",
    key: "publicCommunications",
    sortField: "Year",
  },
  {
    title: "UseInspiredOutputs",
    id: "E73B9C73-40A1-48EF-BD89-570ADBF01B8C",
    grantSolrField: "OutputUseInspiredId_mvf",
    solrField: "OutputUseInspiredId",
    entity: "OutputUseInspired",
    key: "inspirations",
    sortField: "Year",
  },
];

export function getTextWidth(text, font) {
  // https://stackoverflow.com/a/21015393/669561
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

export function numberToStringPandemic(number, language = "de") {
  try {
    if (_.isString(number)) {
      number = parseInt(number, 10);
    }
    const numberDelimiter = {
      de: "'",
      en: ",",
      fr: "\u00A0",
    }[language];

    return String(number).replace(/(.)(?=(\d{3})+$)/g, `$1${numberDelimiter}`);
  } catch (error) {
    log.debug(`cannot format ${number}`);
    return number;
  }
}

export function numberToStringFormat(number, language = "de") {
  try {
    if (_.isString(number)) {
      number = parseInt(number, 10);
    }

    const prec = number.toPrecision(3);
    const rounded = parseFloat(prec);
    if (number >= 1000000000) {
      let resultNumberString = `${rounded / 1000000000}`;
      if (["de", "fr"].includes(language)) {
        resultNumberString = resultNumberString.replace(".", ",");
      }
      resultNumberString += {
        de: " Mrd.",
        en: " b",
        fr: " Mrd",
      }[language];

      return resultNumberString;
    }

    if (number >= 1000000) {
      let resultNumberString = `${rounded / 1000000}`;
      if (["de", "fr"].includes(language)) {
        resultNumberString = resultNumberString.replace(".", ",");
      }
      resultNumberString += {
        de: " Mio.",
        en: " m",
        fr: " Mio",
      }[language];

      return resultNumberString;
    }

    if (number >= 1000) {
      const lowerPart = number % 1000;
      let lowerPartZeroPadded = ("000" + lowerPart).slice(-3);
      let prec = 0;
      if (number < 100000) {
        prec = Math.floor(number / 1000) * 1000;
      }
      if (number >= 100000) {
        prec = number.toPrecision(3);
        lowerPartZeroPadded = "000";
      }
      const rounded1000 = parseFloat(prec);
      return (
        rounded1000 / 1000 +
        {
          de: "'" + lowerPartZeroPadded,
          en: "," + lowerPartZeroPadded,
          fr: "\u00A0" + lowerPartZeroPadded,
        }[language]
      );
    }

    return "" + number;
  } catch (error) {
    log.debug(`cannot format ${number}`);
    return number;
  }
}

export function capitalizeWords(string) {
  return _.join(_.map(_.split(string, " "), _.capitalize), " ");
}

export function trimWords(inputStr, searchInput, replaceStr) {
  // remove '"' if it is the first or last character
  inputStr = inputStr.replace(/^"/, "");
  inputStr = inputStr.replace(/"$/, "");

  let searchWords = [];
  if (searchInput) {
    searchWords = splitTrimWords(_.lowerCase(searchInput));
  }

  let words = _.split(inputStr, " ");

  if (!_.includes(searchWords, words[0].toLowerCase())) {
    if (replaceStr) {
      words[0] = replaceStr;
    } else {
      // drop first
      words = _.drop(words);
    }
  }

  const lastWord = _.last(words).toLowerCase();
  if (
    !_.find(searchWords, (w) => {
      return _.includes(lastWord, w);
    })
  ) {
    if (replaceStr) {
      words[words.length - 1] = replaceStr;
    } else {
      // drop last
      words.pop();
    }
  }

  return _.join(words, " ");
}

/**
 * @param input
 * @param substr string which contains possibly multiple strings
 * @returns {number}
 */
export function firstIndexOfMultiple(input, substr) {
  if (!input || !substr) {
    return -1;
  }
  input = input.toString().toLowerCase();
  substr = _.trim(substr.replace(/"/g, "")).toLowerCase();
  let result = input.indexOf(substr);
  if (result >= 0) {
    return result;
  }

  // check every single word in substr
  const words = splitTrimWords(substr);
  if (words.length > 0) {
    for (let i in words) {
      result = input.indexOf(words[i].replace(/\\/g, ""));
      if (result >= 0) {
        return result;
      }
    }
  }

  return -1;
}

export function replaceWithSpanHighlight(input, substr, cssAttributes) {
  let words = splitTrimWords(substr.replace(/"/g, "")).map((w) => {
    return w.replace(/"/g, "");
  });

  const searchString = _.join(words, " ");

  if (searchString) {
    try {
      const fullResult = input
        .toString()
        .replace(new RegExp(`(${searchString})`, "ig"), (a, b) => {
          return `<span${cssAttributes ? " " + cssAttributes : ""}>${b}</span>`;
        });
      if (input !== fullResult) {
        return fullResult;
      }
    } catch (error) {
      log.debug(error.message);
    }
  }

  const ignoreWords = [
    "in",
    "im",
    "am",
    "der",
    "die",
    "das",
    "und",
    "vom",
    "von",
    "da",
    "zum",
    "zu",
    "and",
    "or",
    "the",
    "a",
    "an",
    "of",
    "for",
    "to",
    "with",
    "on",
    "at",
    "by",
    "from",
    "is",
    "at",
    "et",
    "au",
    "car",
    "en",
    "de",
    "la",
    "le",
    "les",
    "un",
    "une",
    "des",
    "du",
    "de",
    "pour",
  ];
  words = words.filter((w) => {
    return !_.includes(ignoreWords, w);
  });

  if (words.length > 1) {
    let wordResult = input;
    _.forEach(words, (word) => {
      wordResult = wordResult.replace(new RegExp(`(${word})`, "ig"), (a, b) => {
        return `<span${cssAttributes ? " " + cssAttributes : ""}>${b}</span>`;
      });
    });

    return wordResult;
  }

  return input;
}

export function mergeWordsWithDoubleQuotes(wordList) {
  const result = [];
  let wordInQuotes = "";
  _.forEach(wordList, (w) => {
    if (w[0] === '"' && w[w.length - 1] === '"') {
      result.push(w.substr(1).slice(0, -1));
      return;
    }

    if (w[0] === '"') {
      wordInQuotes += '"' + w.substr(1);
      return;
    }

    if (w[w.length - 1] === '"') {
      if (wordInQuotes) {
        wordInQuotes += " " + w.slice(0, -1) + '"';
        result.push(wordInQuotes);
        wordInQuotes = "";
        return;
      } else {
        result.push(w.slice(0, -1));
        return;
      }
    }

    if (wordInQuotes) {
      wordInQuotes += " " + w;
      return;
    }

    result.push(w);
  });

  if (wordInQuotes) {
    // clean up messy string -> remove all double quotes
    return _.map(wordList, (w) => {
      return w.replace(/"/g, "");
    });
  }
  return result;
}

export function splitTrimWords(
  input,
  minWordLength = 2,
  handleBooleans = false,
  handleQuotes = true,
) {
  let keepList = [];
  let removeList = ["AND", "OR", "NOT", "(", ")"];

  if (handleBooleans) {
    keepList = ["AND", "OR", "NOT", "(", ")"];
    removeList = [];
  }

  const inputContainsQuotes = input.indexOf('"') >= 0;

  let words = _.chain(input)
    .split(" ")
    .flatMap((w) => {
      w = _.trim(w);
      if (w[0] === "(" && w[w.length - 1] === ")") {
        return w.slice(1, w.length - 1);
      }
      if (w[0] === "(") {
        return ["(", w.slice(1)];
      }
      if (w[w.length - 1] === ")") {
        return [w.slice(0, w.length - 1), ")"];
      }
      return w;
    })
    .filter((w) => {
      return (
        w &&
        !_.includes(removeList, w) &&
        (inputContainsQuotes ||
          w.length >= minWordLength ||
          _.includes(keepList, w) ||
          /^\d{2}$/.test(w))
      );
    })
    .value();

  // handle double quotes -> merge words with quotes again
  if (handleQuotes) {
    words = mergeWordsWithDoubleQuotes(words);
  }

  // CF-1048: special handling for searches like "nfp 70" or "nrp 22"
  if (words.length === 2 && /^\d{2}$/.test(words[1]) && words[0].length === 3) {
    words = [`"${words[0].replace(/"/g, "")} ${words[1]}"`];
  }

  // remove 'empty' strings in words
  words = _.filter(words, (w) => {
    return _.trim(w);
  });

  return words;
}

export function normalizeDoubleQuotes(input) {
  // see https://en.wikipedia.org/wiki/Quotation_mark#Unicode_code_point_table
  return input
    .replaceAll("\u201C", '"')
    .replaceAll("\u201D", '"')
    .replaceAll("\u201E", '"')
    .replaceAll("\u201F", '"')
    .replaceAll("\u2033", '"')
    .replaceAll("\u2036", '"')
    .replaceAll("\u00AB", '"')
    .replaceAll("\u00BB", '"');
}

export function normalizeSingleQuotes(input) {
  // see https://en.wikipedia.org/wiki/Quotation_mark#Unicode_code_point_table
  return input
    .replaceAll("\u2018", "'")
    .replaceAll("\u2019", "'")
    .replaceAll("\u201a", "'")
    .replaceAll("\u201b", "'")
    .replaceAll("\u2039", "'")
    .replaceAll("\u203A", "'");
}

export function removeSuperfluousWhitespace(input) {
  // trim input
  input = _.trim(input);

  // remove multiple whitespaces with single space
  input = input.replace(/\s+/g, " ");

  // remove whitespace after opening double quote
  input = input.replace(/"\s+/g, '"');

  // remove whitespace before closing double quote
  input = input.replace(/\s+"/g, '"');

  // when there are more than two double quotes, add space between double quoting terms
  let doubleQuoteCount = 0;
  let result = "";

  for (let i = 0; i < input.length; i++) {
    if (input[i] === '"') {
      doubleQuoteCount++;
      if (doubleQuoteCount % 2 === 0 && input[i + 1] !== " ") {
        result += input[i] + " ";
        continue;
      } else if (doubleQuoteCount % 2 === 1 && input[i - 1] !== " ") {
        result += " " + input[i];
        continue;
      }
    }
    result += input[i];
  }

  // trim input
  result = _.trim(result);

  // remove multiple whitespaces with single space
  result = result.replace(/\s+/g, " ");

  return result;
}

export function searchTermsWithBoolean(searchWords) {
  function handleWords(acc, words, lastBoolean = "AND", includesNot = false) {
    const validBooleans = ["AND", "OR"];

    if (words.length) {
      const word = _.head(words);
      const rest = _.tail(words);

      if (_.includes(validBooleans, word)) {
        return handleWords(acc, rest, word);
      } else if (word === "NOT") {
        return handleWords(acc, rest, lastBoolean, true);
      } else if (word[0] === "-") {
        return handleWords(acc, [word.slice(1), ...rest], lastBoolean + "+NOT");
      } else if (word[0] === ")") {
        return [acc, rest];
      } else if (word[0] === "(") {
        const innerResult = handleWords([], rest);
        return handleWords(
          [...acc, [lastBoolean + (includesNot ? "+NOT" : ""), innerResult[0]]],
          innerResult[1],
        );
      } else {
        return handleWords(
          [...acc, [lastBoolean + (includesNot ? "+NOT" : ""), word]],
          rest,
        );
      }
    }

    return [acc, null];
  }

  function removeParenthesesIfNotMatching(words) {
    let counter = 0;
    let valid = true;
    _.forEach(words, (w) => {
      if (w === "(") {
        counter += 1;
      } else if (w === ")") {
        if (counter <= 0) {
          valid = false;
        }
        counter -= 1;
      }
    });

    if (counter !== 0 || !valid) {
      return _.filter(words, (w) => {
        return !_.includes(["(", ")"], w);
      });
    }

    return words;
  }

  return handleWords([], removeParenthesesIfNotMatching(searchWords))[0];
}

export function createTextSearchQuery(searchInput) {
  function createInnerQuery(terms) {
    let result = "(";
    _.forEach(terms, ([bool, word]) => {
      if (_.isArray(word)) {
        result += `+${bool}+${createInnerQuery(word)}`;
      } else if (/^\d{5,6}$/.test(word)) {
        // CF-984 workaround for number only searches -> filter for GrantNumber
        result += `+${bool}+GrantNumber:${word}`;
      } else {
        result += `+${bool}+TextSearch:${word.toLowerCase()}`;
      }
    });
    result += ")";

    // remove first unneeded +AND+
    return result.replace(/^\(\+AND\+/, "(");
  }

  searchInput = normalizeDoubleQuotes(normalizeSingleQuotes(searchInput));
  searchInput = searchInput.replace(/'/g, '"');

  searchInput = searchInput.replace(/\*/g, "");

  // DSS-1372: treat words which contain a hyphen `-` or `:` and `&` as one word with double quotes
  // remove the character in between
  searchInput = searchInput.replace(
    /(\w+(?:[-:&]\w+)+)/g,
    (match) => `"${match.replace(/[-:&]/g, " ")}"`,
  );

  // CF-984 workaround for number only searches -> filter for GrantNumber
  if (/^\d+$/.test(_.trim(searchInput))) {
    return `GrantNumber:${searchInput}`;
  }

  // handle `|` as OR
  searchInput = searchInput.replace(/\|/g, " OR ");

  // remove superfluous whitespaces
  searchInput = removeSuperfluousWhitespace(searchInput);

  // CF-1206 check for multiple trimmed numbers separated by comma
  const numberInputs = _.trim(searchInput)
    .split(",")
    .map((n) => _.trim(n));
  if (numberInputs.length > 1 && _.every(numberInputs, (n) => /^\d+$/.test(n))) {
    return (
      "(" +
      _.join(
        numberInputs.map((n) => `GrantNumber:${n}`),
        "+OR+",
      ) +
      ")"
    );
  }

  let splitTrimWordsResult = splitTrimWords(searchInput, 3, true);

  // surround single words with double quotes
  if (splitTrimWordsResult?.length === 1 && splitTrimWordsResult[0][0] !== '"') {
    splitTrimWordsResult = [`"${splitTrimWordsResult[0]}"`];
  }

  if (splitTrimWordsResult?.length) {
    const searchTerms = searchTermsWithBoolean(splitTrimWordsResult);

    if (searchTerms.length) {
      return createInnerQuery(searchTerms);
    }
  }

  return "";
}

export function createSolrGetRequestUrl(queryData) {
  return (
    `${window.location.origin}/solr/search/select/?` +
    _.join(
      _.map(queryData, (value, key) => {
        if (_.isArray(value)) {
          return _.join(
            _.map(value, (vv) => {
              return `${key}=${vv}`;
            }),
            "&",
          );
        } else {
          return `${key}=${value}`;
        }
      }),
      "&",
    )
  );
}

export function makeSolrPostRequest(queryData, cancelToken, onDownloadProgress) {
  let searchParams = new URLSearchParams();

  _.forEach(queryData, (value, key) => {
    if (_.isArray(value)) {
      _.forEach(value, (subValue) => {
        searchParams.append(key, subValue);
      });
    } else {
      searchParams.append(
        key,
        value.toString().replaceAll("+", " ").replaceAll("%20", " "),
      );
    }
  });

  const postConfig = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  if (cancelToken) {
    postConfig.cancelToken = cancelToken;
  }
  if (onDownloadProgress) {
    postConfig.onDownloadProgress = onDownloadProgress;
  }

  return axios.post(
    `${window.location.origin}/solr/search/select`,
    searchParams,
    postConfig,
  );
}

export function facetResultToObject(facetResult) {
  let result = {};
  _.chunk(facetResult, 2).forEach((entry) => {
    result[entry[0]] = entry[1];
  });
  return result;
}

export function decompressDataStoryData(base64Data, options) {
  // https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
  const compressedContent = window.atob(base64Data);
  /* eslint-disable no-undef */
  return pako.inflate(compressedContent, options);
}

export function groupGrantQueryLevels(grantQueryQ) {
  let result = {};
  _.forOwn(grantQueryQ, (value, key) => {
    const gfmEntry = _.find(GRANT_FILTER_MAPPING, (gfm) => {
      return _.includes(gfm.grantSolrFields, key);
    });

    if (gfmEntry) {
      if (result[gfmEntry.id]) {
        result[gfmEntry.id].push(value);
      } else {
        result[gfmEntry.id] = [value];
      }
    } else {
      result[key] = [value];
    }
  });

  return _.mapValues(result, (queryValue) => {
    if (queryValue.length > 1) {
      return `(${queryValue.join("+OR+")})`;
    } else {
      return queryValue[0];
    }
  });
}

export function createSolrFirstDateFromYear(year) {
  if (year) {
    if (year === "*") {
      return "*";
    }
    return `${year}-01-01T00:00:00Z`;
  }
  return "*";
}

export function createSolrLastDateFromYear(year) {
  if (year) {
    if (year === "*") {
      return "*";
    }
    return `${year}-12-31T23:59:59Z`;
  }
  return "*";
}

export function tweenColor(
  startColor,
  middleColor,
  endColor,
  value,
  middleValue = 0.5,
) {
  if (value < middleValue) {
    return [
      Math.round(
        startColor[0] +
          (middleColor[0] - startColor[0]) * easeOutCubic(value) * (1 / middleValue),
      ),
      Math.round(
        startColor[1] +
          (middleColor[1] - startColor[1]) * easeOutCubic(value) * (1 / middleValue),
      ),
      Math.round(
        startColor[2] +
          (middleColor[2] - startColor[2]) * easeOutCubic(value) * (1 / middleValue),
      ),
    ];
  }

  value = value - middleValue;
  return [
    Math.round(
      middleColor[0] +
        (endColor[0] - middleColor[0]) * easeOutCubic(value) * (1 / (1 - middleValue)),
    ),
    Math.round(
      middleColor[1] +
        (endColor[1] - middleColor[1]) * easeOutCubic(value) * (1 / (1 - middleValue)),
    ),
    Math.round(
      middleColor[2] +
        (endColor[2] - middleColor[2]) * easeOutCubic(value) * (1 / (1 - middleValue)),
    ),
  ];
}

function easeOutQuad(x) {
  return 1 - (1 - x) * (1 - x);
}

function easeInQuad(x) {
  return x * x;
}

function easeOutCubic(x) {
  return 1 - Math.pow(1 - x, 3);
}

function easeOutSine(x) {
  return Math.sin((x * Math.PI) / 2);
}
